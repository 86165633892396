@charset "UTF-8";
/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@font-face {
  font-family: "Futura Bk BT";
  src: url("./assets/fonts/futura-bk-bt.ttf");
}
@font-face {
  font-family: "Futura Hv BT";
  src: url("./assets/fonts/futura-hv-bt.ttf");
}
@font-face {
  font-family: "Erato";
  src: url("./assets/fonts/EratoRegular.otf");
}
h1 {
  font-family: "Erato";
}

p {
  font-family: "Futura Bk BT";
  font-size: 16px;
  margin: 0 0 10px 0;
}

.red {
  color: #e60000 !important;
}

.blue {
  color: #1f4e79 !important;
}

.green {
  color: #136957 !important;
}

.purple {
  color: #A349A4;
}

ion-header h1 {
  font-size: 13px;
  font-family: "Futura Hv BT" !important;
  font-style: normal;
  font-weight: 900;
  margin: 0;
  width: 216px;
  max-width: 100%;
}

ion-button {
  font-family: "Futura Bk BT";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  height: 48px;
}

.content-module.type_title_on {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 10px;
  margin-top: 3rem;
  filter: drop-shadow(0 0 0px #fff);
}
.content-module.type_title_on::before, .content-module.type_title_on::after {
  content: "";
  display: block;
  align-self: center;
  height: 2px;
  background-color: #007D57;
  width: 100%;
  min-width: 50px;
}
.content-module.type_title_on h1 {
  display: inline-block;
  align-self: center;
  margin: 0;
  color: #007D57;
  text-shadow: 1px 1px 1px #ffffff;
}
.content-module h1 {
  margin-bottom: 0;
  word-break: normal;
  font-size: 1.2em;
}
.content-module p {
  word-break: normal;
}

.logo-footer {
  margin: 15vh 0;
  text-align: center;
}
.logo-footer .logo {
  margin-left: 40px;
  margin-right: 40px;
}

.module-card {
  position: relative;
  height: 240px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.module-card .body-card {
  cursor: pointer;
  background: rgba(0, 131, 190, 0.8);
  border-radius: 25px 25px 0px 0px;
  position: absolute;
  bottom: 0;
  padding: 15px 20px;
  width: 100%;
}
.module-card .title-card {
  font-family: "Futura Bk BT";
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  margin: 0;
  margin-bottom: 5px;
}
.module-card .description-card {
  font-family: "Futura Bk BT";
  font-size: 16px;
  color: #fff;
  margin: 0;
}
.module-card .center {
  text-align: center;
}
.module-card ion-icon, .module-card i {
  font-size: 30px;
  color: #fff;
}

.progress-bar-module {
  font-family: "Futura LT Medium";
  text-align: left;
}
.progress-bar-module p {
  margin-bottom: 0;
}
.progress-bar-module .title {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}
.progress-bar-module .value {
  font-size: 18px;
  color: #0083BE;
  margin-top: 0;
}
.progress-bar-module .value span {
  padding: 0 !important;
}

ion-footer {
  background: #fff;
}
ion-footer .footer-controls {
  --background: #0083BE;
  border-radius: 15px 15px 0 0;
  color: #fff;
}
ion-footer .footer-controls ion-col {
  padding: 0;
}
ion-footer .footer-controls p {
  padding: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-family: "Futura Bk BT";
}
ion-footer .footer-controls ion-icon, ion-footer .footer-controls i {
  font-size: 30px;
  margin-bottom: -10px;
}

audio {
  width: 100%;
  height: 60px;
  margin: 20px 0;
}

audio::-webkit-media-controls {
  background: #0083BE;
  border: 2px solid #0083BE;
  border-radius: 12px;
  height: 80px;
}

audio::-webkit-media-controls-enclosure {
  width: 100%;
  max-width: 800px;
  height: 70px;
  flex-shrink: 0;
  bottom: 0;
  text-indent: 0;
  padding: 0;
  box-sizing: border-box;
  background: transparent;
}

audio::-webkit-media-controls-panel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  -webkit-user-select: none;
  position: relative;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  text-align: right;
  bottom: auto;
  height: 60px;
  border-radius: 4px;
  transition: opacity 0.3s;
  background: transparent;
}

audio:-webkit-full-page-media, video:-webkit-full-page-media {
  max-height: 100%;
  max-width: 100%;
}

audio:-webkit-full-page-media::-webkit-media-controls-panel,
audio::-webkit-media-controls-mute-button {
  -webkit-appearance: media-mute-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 35px;
  height: 30px;
  line-height: 30px;
  margin: 0 6px 0 0;
  padding: 0;
  background-color: initial;
  color: inherit;
}

audio::-webkit-media-controls-overlay-enclosure {
  display: none;
}

audio::-webkit-media-controls-play-button, video::-webkit-media-controls-play-button {
  -webkit-appearance: media-play-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: 9px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}

audio::-webkit-media-controls-timeline-container, video::-webkit-media-controls-timeline-container {
  -webkit-appearance: media-controls-background;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1;
  -webkit-user-select: none;
  height: 16px;
  min-width: 0;
}

audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-time-remaining-display {
  -webkit-appearance: media-current-time-display;
  -webkit-user-select: none;
  flex: none;
  display: flex;
  border: none;
  cursor: default;
  height: 30px;
  margin: 0 9px 0 0;
  padding: 0;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-style: normal;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-decoration: none;
}

audio::-webkit-media-controls-timeline {
  -webkit-appearance: media-slider;
  display: flex;
  flex: 1 1 auto;
  height: 8px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 25px;
  border: initial;
  color: inherit;
}

audio::-webkit-media-controls-volume-slider {
  -webkit-appearance: media-volume-slider;
  display: flex;
  /* The 1.9 value was empirically chosen to match old-flexbox behaviour
   * and be aesthetically pleasing.
   */
  flex: 1 1.9 auto;
  height: 8px;
  max-width: 70px;
  margin: 0 15px 0 0;
  padding: 0;
  background-color: transparent;
  min-width: 15px;
  border: initial;
  color: inherit;
}

input[type=range i]::-webkit-media-slider-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  /* This property is updated by C++ code. */
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  border: 1px solid rgba(230, 230, 230, 0.35);
  border-radius: 4px;
  background-color: transparent;
  /* Background drawing is managed by C++ code to draw ranges. */
}

/* The negative right margin causes the track to overflow its container. */
input[type=range i]::-webkit-media-slider-container > div {
  margin-right: -14px;
}

input[type=range i]::-webkit-media-slider-thumb {
  margin-left: -7px;
  margin-right: -7px;
}

audio::-webkit-media-controls-fullscreen-button {
  -webkit-appearance: media-enter-fullscreen-button;
  display: flex;
  flex: none;
  border: none;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: -5px;
  margin-right: 9px;
  padding: 0;
  background-color: initial;
  color: inherit;
}

audio::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}

audio::-webkit-media-controls-fullscreen-volume-slider {
  display: none;
}

audio::-webkit-media-controls-fullscreen-volume-min-button {
  display: none;
}

audio::-webkit-media-controls-fullscreen-volume-max-button {
  display: none;
}

ion-progress-bar {
  height: 6px;
  border-radius: 30px;
}

.audio_container {
  position: relative;
  border-radius: 15px;
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 15px;
}
.audio_container audio {
  width: 100%;
  margin: 0;
}
.audio_container .ppq-audio-player {
  display: grid;
  grid-template-columns: 47px 36px 1fr 36px;
  grid-gap: 15px;
  padding: 1rem;
  height: auto;
  line-height: normal;
  background-color: transparent;
  align-self: center;
  width: 100%;
}
.audio_container .ppq-audio-player.player-playing .play-pause-btn .play-pause-icon::after {
  background-image: url(../src/assets/images/icons/pause.svg);
}
.audio_container .ppq-audio-player.player-paused .play-pause-btn .play-pause-icon::after {
  background-image: url(../src/assets/images/icons/play.svg);
}
.audio_container .ppq-audio-player .play-pause-btn {
  order: 0;
}
.audio_container .ppq-audio-player .play-pause-btn .play-pause-icon {
  border-color: #0083BE;
  border-width: 2px;
}
.audio_container .ppq-audio-player .play-pause-btn .play-pause-icon::after {
  background-image: url(../src/assets/images/icons/play.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.audio_container .ppq-audio-player .player-time-current {
  order: 1;
}
.audio_container .ppq-audio-player .player-bar {
  order: 2;
  position: relative;
  background-color: #f5f5f5;
  border-radius: 30px;
  overflow: hidden;
}
.audio_container .ppq-audio-player .player-bar .player-bar-played {
  background: #0083BE;
}
.audio_container .ppq-audio-player .player-time-duration {
  order: 3;
}
.audio_container .ppq-audio-player .player-time, .audio_container .ppq-audio-player .play-pause-btn, .audio_container .ppq-audio-player .player-bar {
  float: none;
  margin: 0;
  align-self: center;
  width: 100%;
}
.audio_container .download_container {
  position: relative;
  align-self: center;
}
.audio_container .download_container .icon_download {
  display: block;
  position: relative;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  width: 30px;
  max-width: 100%;
  margin: auto;
}
.audio_container .download_container .icon_download::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.audio_container .download_container .icon_download svg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  fill: #0083BE;
}

ol.accordion, .content_text ol, .content_text ul {
  margin-bottom: 0;
  margin-top: 10px;
}
ol.accordion li, .content_text ol li, .content_text ul li {
  margin: 0 0 8px !important;
  font-size: 14px;
  list-style: disc;
}

ul[type=disc] li[type=disc] {
  list-style: disc !important;
}

ul[type=circle] li, li[type=circle] {
  list-style: circle !important;
}

ol.accordion li, .content_text ol li {
  list-style: decimal;
}

.content_text ol li ul li {
  list-style: disc !important;
}

.content_text ul li, ul.accordion li {
  list-style: disc;
}

.row_container_details_module_accordion ul, .row_container_details_module_accordion ol, .content_text ul, .content_text ol {
  margin-top: 15px;
}
.row_container_details_module_accordion ul[type=check], .content_text ul[type=check] {
  list-style-type: none !important;
  position: relative;
}
.row_container_details_module_accordion ul[type=check] li, .content_text ul[type=check] li {
  list-style: none !important;
}
.row_container_details_module_accordion ul[type=check] li:before, .content_text ul[type=check] li:before {
  position: absolute;
  left: 20px;
  content: "✓";
}
.row_container_details_module_accordion ol[type=a] li, .content_text ol[type=a] li {
  list-style: lower-latin !important;
}
.row_container_details_module_accordion ol[type=a] li ul[type=check] li, .content_text ol[type=a] li ul[type=check] li {
  list-style: none !important;
}
.row_container_details_module_accordion ol[type=none] li, .row_container_details_module_accordion ul[type=none] li, .content_text ol[type=none] li, .content_text ul[type=none] li {
  list-style: none !important;
}
.row_container_details_module_accordion li.none, .content_text li.none {
  list-style: none !important;
}

.content_text ol li {
  list-style: decimal !important;
}

.content_table {
  background-color: #fafafa;
  padding: 15px;
  max-width: 100%;
  border-radius: 10px;
  margin: 15px 0;
}
.content_table .contnt_btns_scroll input {
  background-color: #0083BE;
  color: #fff;
  width: 38px;
  height: 38px;
  line-height: 32px;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 10px;
  border: 0;
  outline: none;
}
.content_table .table_inner {
  overflow: hidden;
  overflow-x: auto;
  max-width: 100%;
  padding: 10px;
}
.content_table .table_inner table {
  border: 0;
  /* border-spacing: 0 15px; */
  border-collapse: separate;
  width: auto;
  min-width: 100%;
}
.content_table .table_inner table td {
  border: 0;
  padding: 12px;
  text-align: center;
  word-break: normal;
  vertical-align: middle;
  min-width: 100px;
}
.content_table .table_inner table tr:not(:first-child):not(.tr_header) {
  /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.10); border-radius: 5px; */
}
.content_table .table_inner table tr:not(:first-child):not(.tr_header) td {
  font-size: 12px;
  background: #fff;
  border: 1px solid #efefef;
}
.content_table .table_inner table tr:not(:first-child):not(.tr_header) td.td_head {
  font-size: 12px;
  font-weight: 700;
  background: #007D57;
  color: #fff;
  line-height: normal;
}
.content_table .table_inner table tr:first-child td, .content_table .table_inner table tr.tr_header td {
  font-size: 12px;
  font-weight: 700;
  background: #007D57;
  color: #fff;
  line-height: normal;
}
.content_table .table_inner table tr:first-child td.td_head, .content_table .table_inner table tr.tr_header td.td_head {
  font-size: 12px;
  font-weight: 700;
  background: #007D57;
  color: #fff;
  line-height: normal;
}
.content_table .table_inner table tr:first-child td:first-child, .content_table .table_inner table tr.tr_header td:first-child {
  border-radius: 5px 0 0 0;
}
.content_table .table_inner table tr:first-child td:last-child, .content_table .table_inner table tr.tr_header td:last-child {
  border-radius: 0 5px 0 0;
}

.content_image_pors {
  position: relative;
  border: 1px solid #dedede;
  border-radius: 16px;
  overflow: hidden;
  margin: 1rem 0;
}
.content_image_pors img {
  display: block;
  max-width: 100%;
  margin: auto;
  width: auto;
  max-height: 600px;
}
.content_image_pors .button_eye {
  font-size: 14px;
  text-align: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  border-radius: 30px;
  background-color: #007D57;
  color: #fff;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.content_image_pors .button_eye:hover {
  background-color: #34B233;
}
.content_image_pors .button_eye ion-icon, .content_image_pors .button_eye i {
  padding-right: 5px;
  font-size: 18px;
}

.modal-wrapper.sc-ion-modal-md, .modal-shadow.sc-ion-modal-md {
  background-color: #fff !important;
}

ion-modal.popup_image {
  --width: 100%;
  --height: 100%;
  --background: #000;
}
ion-modal.popup_image .ion-page {
  align-items: center;
  justify-content: center;
}
ion-modal.popup_image .ion-page img {
  display: block;
  margin: auto;
  max-width: 100%;
  width: auto;
  height: auto;
}

.close_i {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 51px;
  font-size: 30px;
  cursor: pointer;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  z-index: 9;
}

.quote-section {
  display: grid;
  grid-template-columns: 1fr;
  width: 400px;
  max-width: 100%;
  margin-right: 0;
  margin-left: auto;
}
.quote-section h3 {
  max-width: calc(100% - 35px * 2);
  margin: 0 auto;
  font-family: "Erato";
  font-weight: 900;
  text-transform: uppercase;
  font-size: 1.3em;
}
.quote-section h1 {
  text-transform: uppercase;
  font-family: "Erato";
  margin: 2rem 0 0;
  font-weight: 900;
}
.quote-section h6 {
  font-family: "Erato";
  font-style: italic;
  text-transform: uppercase;
}

.highlight {
  width: calc(50% - 20px);
  margin: auto;
  background: #fdfaed;
  padding: 1rem 1.5rem;
  font-size: 14px;
  text-align: left;
  margin-bottom: 15px;
  line-height: normal;
  border-radius: 9px;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.2));
  position: relative;
}
.highlight::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/images/granito.jpg");
  opacity: 0.14;
  pointer-events: none;
}
.highlight * {
  font-size: 14px;
}

.subtitle, .content-module h1, .content-module h2 {
  font-size: 20px;
  text-align: left;
  font-family: "Futura Bk BT";
  color: #0083BE;
  /* $fourth-color */
  margin: 10px 0 2px;
}

.content-module strong {
  font-weight: strong;
  color: #000000;
}

.content-module span {
  font-weight: strong;
  color: #0083BE;
}

.lightbox_btn span {
  color: #fff !important;
}

@media screen and (min-width: 768px) {
  li, ol.accordion li, ul.accordion li, .content_text ol li, .content_text ul li {
    font-size: 16px !important;
  }
}
.row_type {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: wrap;
  -ms-flex-flow: wrap;
  flex-flow: wrap;
}
.row_type.ds_row {
  -moz-flex-flow: row;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
}
.row_type.ds_column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-flow: column;
  -ms-flex-flow: column;
  flex-flow: column;
}
.row_type.ds_column-reversed {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -webkit-flex-flow: column-reverse;
  -ms-flex-flow: column-reverse;
  flex-flow: column-reverse;
}

.align_start {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.justify_start {
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.align_centr {
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.justify_centr {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.align_end {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.justify_end {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.order_1 {
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
}

.order_2 {
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
}

.order_3 {
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
}

.lightbox_btn {
  text-align: center;
}
.lightbox_btn .btgg {
  display: inline-block;
  text-align: center;
  padding: 10px 20px;
  background-color: #009B48;
  color: #fff;
  border-radius: 40px;
  cursor: pointer;
  margin: 10px auto;
  max-width: calc(100% - 15px);
}
.lightbox_btn .btgg:hover {
  background-color: #007D57;
}
.lightbox_btn .btgg ion-icon, .lightbox_btn .btgg i {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 4px;
}
.lightbox_btn .btgg span {
  vertical-align: middle;
}

@media (min-width: 720px) {
  ion-grid {
    width: 75%;
  }

  .padding-card {
    padding: 10px;
  }

  .ion-padding.content-module {
    --padding-top: 0;
    padding-top: 0;
  }

  .content-module:not(.type_title_on) {
    --padding-top: 0;
  }
  .content-module h1 {
    font-size: 1.8em;
  }
}
@media screen and (max-width: 767px) {
  .container_bg-desktop {
    position: relative;
    height: 100%;
  }
  .container_bg-desktop .content_bg {
    height: 100%;
    max-height: 100%;
  }
  .container_bg-desktop .content_int {
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    max-height: 100%;
    height: auto;
    display: block;
  }

  .type_inn-desktop-scroll {
    --overflow: hidden;
  }

  .scroller_notification {
    bottom: 5% !important;
    transform: translate(-50%, -50%) scale(0.5) !important;
  }

  .grid_co {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .content-module.type_title_on::before, .content-module.type_title_on::after {
    min-width: 20px;
  }

  .ionic__none {
    display: none;
  }

  .container_bg-desktop .content_int.intro ion-grid {
    min-height: 100%;
    display: flex;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 {
    min-height: 100%;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row {
    min-height: 100%;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .title_inner-ctt-now {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col {
    min-height: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module {
    min-height: 100%;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section {
    text-align: center;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section img {
    max-width: 100%;
    width: auto;
    margin: 1rem auto 2em;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section h3 {
    margin: 0;
    max-width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section h1 {
    font-size: 20px;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .highlight {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .lightbox {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .subtitle_inn {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .row_container_details_module {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .container_bg-desktop {
    position: relative;
    background-image: url("../src/assets/images/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    position: relative;
    height: 100%;
  }
  .container_bg-desktop .content_bg {
    background-color: #fff;
    width: 1250px;
    max-width: 100%;
    height: auto;
    max-height: 95%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    display: flex;
  }
  .container_bg-desktop .content_bg.intro .content_int {
    height: 580px;
    max-height: 100%;
  }
  .container_bg-desktop .content_bg.intro::before {
    background-size: 110%;
    background-position: 500% center;
    opacity: 1;
  }
  .container_bg-desktop .content_bg::before {
    content: "";
    background-image: url("../src/assets/images/WCS_w.svg");
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: -50% center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.14;
  }
  .container_bg-desktop .content_int {
    overflow: hidden;
    overflow-y: auto;
    padding: 0;
    max-height: 100%;
    height: auto;
    display: block;
    position: relative;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro {
    height: 580px;
    max-height: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid {
    min-height: 100%;
    display: flex;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 {
    min-height: 100%;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row {
    min-height: 100%;
    width: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .title_inner-ctt-now {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col {
    min-height: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module {
    min-height: 100%;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section {
    height: 100%;
    display: grid;
    grid-template-rows: 4fr 1fr;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section h3 {
    align-self: flex-end;
    margin: 0 auto 5px;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section h1 {
    align-self: center;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .quote-section h6 {
    align-self: flex-end;
    margin: 0 0 2rem;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .highlight {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .lightbox {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .subtitle_inn {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .slide-3 .ctt_row .ctt_col .content-module .row_container_details_module {
    display: none;
  }
  .container_bg-desktop .content_int.intro ion-grid .content_details {
    min-height: 100%;
  }
  .container_bg-desktop .content_int.intro::before {
    background-size: 105%;
    background-position: calc(100% * 10) center;
    opacity: 1;
  }
  .container_bg-desktop .content_int ion-grid {
    width: auto;
    height: auto;
    max-width: 100%;
  }
  .container_bg-desktop .content_int .space-scroll-y {
    height: 10vh;
  }
  .container_bg-desktop .content_int::-webkit-scrollbar {
    width: 14px;
  }
  .container_bg-desktop .content_int::-webkit-scrollbar-button {
    height: 50px;
  }
  .container_bg-desktop .content_int::-webkit-scrollbar-track {
    background-color: #d6d6d6;
    border-radius: 100px;
  }
  .container_bg-desktop .content_int::-webkit-scrollbar-thumb {
    background-color: rgba(0, 131, 190, 0.8);
    border-radius: 10px;
  }
  .container_bg-desktop .content_int::-webkit-scrollbar-corner {
    border-radius: 30px;
  }
  .container_bg-desktop ion-footer {
    position: absolute;
    background: transparent;
    bottom: 10%;
    transform: translate(-50%, 50%);
    left: 50%;
    min-width: 400px;
    width: auto;
    max-width: 100%;
  }
  .container_bg-desktop ion-footer .footer-controls {
    border-radius: 15px;
  }

  .desktop_none {
    display: none;
  }

  .type_inn-desktop-scroll {
    --overflow: hidden;
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px) {
  ion-header {
    background: #fff;
    height: 51px;
  }
  ion-header ion-toolbar {
    transform: scale(0.8);
    transform-origin: top center;
  }
}
@media screen and (min-width: 992px) {
  ion-modal.popup_image .ion-page .close_i {
    top: 5%;
    right: 5%;
  }

  .progress-bar-module {
    padding: 1em 5em;
  }
}
.link-card {
  cursor: pointer;
}

.content_img-popup {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  background-color: #000;
  height: auto;
  max-height: 100%;
}
.content_img-popup img {
  background-color: #dedede;
}

.subtitle_module {
  float: right;
  text-align: right;
}

.grid_co {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
  margin-top: 10px;
}
.grid_co .co1 {
  line-height: normal;
  margin: 0;
}
.grid_co .co2 {
  line-height: normal;
  margin: 0;
}

.pd-0 {
  padding: 0;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.scroller_notification {
  position: fixed;
  bottom: 0%;
  right: 0%;
  transform: translate(-50%, -50%) scale(0.8);
  transform-origin: center;
  pointer-events: none;
}
.scroller_notification .arrows {
  width: 60px;
  height: 72px;
  display: block;
  margin: auto;
}
.scroller_notification .arrows path {
  stroke: #2b94c3;
  fill: transparent;
  stroke-width: 2px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}
.scroller_notification .arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
  /* Safari 和 Chrome */
}
.scroller_notification .arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  /* Safari 和 Chrome */
}
.scroller_notification .arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  /* Safari 和 Chrome */
}

@media screen and (max-width: 1199px) {
  .scroller_notification {
    transform: translate(-50%, -50%) scale(0.6);
  }
}
.popupSelectorDownload {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.popupSelectorDownload .content_popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 14px;
  width: 400px;
  max-width: calc(100% - 15px);
  color: #000;
  padding: 2rem 1.5rem 1.5rem;
  max-height: calc(100% - 20px);
  height: 700px;
}
.popupSelectorDownload .content_popup .close_popup {
  position: absolute;
  top: 5px;
  right: 5px;
  --border-radius: 50%;
  margin: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  --background: #ef7474;
  --color: #fff;
  z-index: 2;
}
.popupSelectorDownload .content_popup form {
  position: relative;
  display: grid;
  grid-gap: 15px;
  position: relative;
  z-index: 1;
  grid-template-rows: auto 1fr auto;
  min-height: 100%;
  max-height: 100%;
}
.popupSelectorDownload .content_popup form .download_all {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  position: relative;
  padding: 10px 15px;
}
.popupSelectorDownload .content_popup form .download_all .content_check {
  position: relative;
  display: block;
}
.popupSelectorDownload .content_popup form .download_all .content_check input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}
.popupSelectorDownload .content_popup form .download_all .content_check input:checked ~ .module_download span {
  background-color: #0083BE;
}
.popupSelectorDownload .content_popup form .download_all .content_check input:checked ~ .module_download span::after {
  display: block;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download {
  padding: 10px 15px;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download span {
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  border-radius: 50%;
  align-self: center;
  border: 2px solid #0083BE;
  background-color: #fff;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download span::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download span::after {
  content: "";
  position: absolute;
  left: calc(50% - 6px);
  top: 50%;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
  display: none;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download .all_selector {
  grid-column: 2/span 2;
  align-self: center;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download .all_selector ion-icon, .popupSelectorDownload .content_popup form .download_all .content_check .module_download .all_selector i {
  display: inline-block;
  vertical-align: middle;
  font-size: 22px;
  margin-right: 10px;
}
.popupSelectorDownload .content_popup form .download_all .content_check .module_download .all_selector h4 {
  font-size: 16px;
  margin: 0;
  line-height: 1.4;
  vertical-align: middle;
  display: inline-block;
  font-weight: 700;
  color: #0083BE;
}
.popupSelectorDownload .content_popup form .contnt_inner {
  position: relative;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 15px;
  grid-row: 2/span 2;
  overflow: hidden;
  max-height: 100%;
  display: grid;
  grid-template-columns: 100%;
}
.popupSelectorDownload .content_popup form .contnt_inner .header_title {
  border-bottom: 1px solid #dedede;
  margin: 0;
  display: block;
  width: 100%;
  padding: 5px 10px 10px;
}
.popupSelectorDownload .content_popup form .contnt_inner .header_title h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: #009B48;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules {
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;
  display: grid;
  grid-gap: 0;
  grid-template-columns: 100%;
  grid-row: 2/span 2;
  position: relative;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 0 0;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  border-left: 2px dashed #009B48;
  left: 30px;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module input:checked ~ .module_download span {
  background-color: #009B48;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module input:checked ~ .module_download span::after {
  display: block;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module .module_download {
  padding: 10px 15px;
  position: relative;
  display: grid;
  grid-template-columns: 30px 1fr;
  grid-gap: 10px;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module .module_download span {
  width: 100%;
  max-width: 100%;
  display: block;
  position: relative;
  border-radius: 50%;
  align-self: center;
  border: 2px solid #009B48;
  background-color: #fff;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module .module_download span::before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module .module_download span::after {
  content: "";
  position: absolute;
  left: calc(50% - 6px);
  top: 50%;
  width: 5px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
  -ms-transform: rotate(45deg) translate(-50%, -50%);
  transform: rotate(45deg) translate(-50%, -50%);
  display: none;
}
.popupSelectorDownload .content_popup form .contnt_inner .download_modules .content_download-module .module_download h4 {
  grid-column: 2/span 2;
  font-size: 16px;
  margin: 0;
  line-height: 1.4;
  align-self: center;
}
.popupSelectorDownload .content_popup form .content_download-button {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 10px 15px;
}
.popupSelectorDownload .content_popup form .content_download-button ion-button {
  width: 100%;
  --border-radius: 4rem;
  height: 38px;
  margin: 0;
}

.footer_progress_inner {
  position: relative;
}
.footer_progress_inner .down_and_up {
  bottom: 100%;
  display: block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #dedede;
  border-bottom: none;
  position: absolute;
  right: 0;
  background-color: #fff;
  font-size: 22px;
}
.footer_progress_inner .down_and_up i, .footer_progress_inner .down_and_up ion-icon {
  font-size: inherit;
}
.footer_progress_inner .content_progress_steps {
  position: relative;
  height: 50px;
}
.footer_progress_inner .content_progress_steps ion-progress-bar {
  height: 15px;
}
.footer_progress_inner .content_progress_steps .steps {
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 2px;
}
.footer_progress_inner .content_progress_steps .steps::-webkit-scrollbar {
  height: 6px;
}
.footer_progress_inner .content_progress_steps .steps::-webkit-scrollbar-button {
  height: 50px;
}
.footer_progress_inner .content_progress_steps .steps::-webkit-scrollbar-track {
  background-color: #d6d6d6;
  border-radius: 100px;
}
.footer_progress_inner .content_progress_steps .steps::-webkit-scrollbar-thumb {
  background-color: rgba(0, 131, 190, 0.8);
  border-radius: 10px;
}
.footer_progress_inner .content_progress_steps .steps::-webkit-scrollbar-corner {
  border-radius: 10px;
}
.footer_progress_inner .content_progress_steps .steps {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row;
  grid-gap: 2.5px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.25);
  border-radius: 10px;
}
.footer_progress_inner .content_progress_steps .steps:hover {
  background-color: #fff;
}
.footer_progress_inner .content_progress_steps .steps .step_title {
  display: block;
  height: 50%;
  margin-top: 20px;
  background-color: rgba(0, 155, 72, 0.2);
  color: #009B48;
  min-width: 20px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
}
.footer_progress_inner .content_progress_steps .steps .step_title:hover {
  background-color: #009B48;
  color: #fff;
}
.footer_progress_inner .content_progress_steps .steps .step_title:hover::before {
  content: attr(id);
  display: block;
  position: absolute;
  bottom: calc(23px);
  background-color: #000;
  color: #fff;
  font-size: 12px;
  border-radius: 6px;
  line-height: normal;
  white-space: nowrap;
  padding: 1px 10px;
}
.footer_progress_inner .content_progress_steps .steps .step_title.active {
  background-color: #0083BE;
  color: #fff;
}
.footer_progress_inner .content_progress_steps .steps .step_title.active:hover {
  background-color: #0083BE;
  color: #fff;
}
.footer_progress_inner .content_progress_steps .steps .step_title:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.footer_progress_inner .content_progress_steps .steps .step_title:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.icon__cap {
  display: inline-block;
  width: 1em;
  height: 1em;
  contain: strict;
  fill: currentcolor;
  box-sizing: content-box !important;
  /* pointer-events: none; */
}
.icon__cap[slot=start] {
  margin: 0px 0.3em 0px -0.3em;
}
.icon__cap[slot=start] {
  margin-left: unset;
  margin-right: unset;
  margin-inline: -0.3em 0.3em;
}
.icon__cap.ion__icon {
  font-size: 1.4em;
}
.icon__cap .icon-inner, .icon__cap .ionicon, .icon__cap svg {
  display: block;
  height: 100%;
  width: 100%;
}
.icon__cap .ionicon {
  stroke: currentcolor;
}
.icon__cap .ionicon-stroke-width {
  stroke-width: var(--ionicon-stroke-width, 32px);
}
.icon__cap .ionicon-fill-none {
  fill: none;
}

.flex-one {
  flex: 1;
}

.select__language-popover {
  --width: auto;
}

app-about-us .ion__content {
  flex: 1 1 0%;
  width: 100%;
  height: 100%;
  contain: size style;
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  background-image: url("./assets/images/bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}
app-about-us .ion__content .content_slide {
  background-color: #fff;
  width: 1250px;
  max-width: 100%;
  height: 100%;
  max-height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  padding: 0 15px;
  position: relative;
  display: flex;
}
app-about-us .ion__content .content_slide::before {
  content: "";
  background-image: url("./assets/images/WCS_w.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 80%;
  background-position-x: -152%;
  background-position-y: center;
  opacity: 0.2;
}
app-about-us .ion__content .content_slide ion-slides {
  height: 100%;
}
app-about-us .ion__content .content_slide ion-slides ion-slide {
  height: 100%;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll {
  height: auto;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  color: #000;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid {
  width: 100%;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content__inner {
  padding-bottom: 3rem;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content-module.type_title_on {
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid li, app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid ol.accordion li, app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid ul.accordion li, app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content_text ol li, app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content_text ul li {
  text-align: left;
  line-height: 20px;
  font-family: "Futura Bk BT";
  margin-bottom: 15px;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid p {
  text-align: left;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content__desc {
  position: relative;
}
app-about-us .ion__content .content_slide ion-slides .content__scroll ion-grid .content__desc img {
  width: 600px;
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  app-about-us .ion__content {
    background: #fff;
  }
  app-about-us .ion__content .content_slide {
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    display: flex;
    background: #fff;
  }
  app-about-us .ion__content .content_slide::before {
    display: none;
  }
  app-about-us .ion__content .content_slide ion-slides {
    height: 100%;
    max-height: 100%;
  }
  app-about-us .ion__content .content_slide ion-slides .swiper-wrapper {
    max-height: 100%;
    color: #dedeed;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide {
    height: 100%;
    align-items: flex-start;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll::-webkit-scrollbar {
    display: none;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll ion-grid .content__inner {
    padding-bottom: 1rem;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll ion-grid .content-module.type_title_on {
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll ion-grid p, app-about-us .ion__content .content_slide ion-slides ion-slide .content__scroll ion-grid li {
    font-size: 15px;
  }
  app-about-us .ion__content .swiper-wrapper {
    max-height: 100%;
  }
}
@media screen and (min-width: 768px) {
  app-about-us .ion__content .content_slide ion-slides .content__scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 131, 190, 0.8);
    border-radius: 10px;
  }
  app-about-us .ion__content .content_slide ion-slides .content__scroll::-webkit-scrollbar-corner {
    border-radius: 30px;
  }
  app-about-us .ion__content .content_slide ion-slides .content__scroll::-webkit-scrollbar-track {
    background-color: #d6d6d6;
    border-radius: 100px;
  }
  app-about-us .ion__content .content_slide ion-slides .content__scroll::-webkit-scrollbar-button {
    height: 50px;
  }
  app-about-us .ion__content .content_slide ion-slides .content__scroll::-webkit-scrollbar {
    width: 14px;
  }
  app-about-us .seg_tabs ion-label {
    word-break: normal;
    line-height: 18px;
    white-space: normal;
    height: auto;
    text-align: left;
    font-size: 13px;
  }
}

app-aboutus ion-content.about-page * {
  word-break: normal;
}
app-aboutus ion-content.about-page h1 {
  font-family: "Erato" !important;
  text-align: center !important;
}
app-aboutus ion-content.about-page h2 {
  color: #0083be;
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 30px;
  margin-top: 50px;
  font-weight: normal;
}
app-aboutus ion-content.about-page .header-page {
  padding: 80px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
app-aboutus ion-content.about-page .header-page .title-page {
  background: #fff;
  border-radius: 15px;
  padding: 20px;
}
app-aboutus ion-content.about-page .header-page .title-page h1 {
  font-family: "Futura Bk BT";
  font-size: 18px;
  text-align: left;
  margin: 0;
}
app-aboutus ion-content.about-page .content-page {
  padding: 0 20px;
}
app-aboutus ion-content.about-page .content-page h1 {
  font-family: "Erato";
}
app-aboutus ion-content.about-page .content-page p {
  font-family: "Futura Bk BT";
}
app-aboutus ion-content.about-page .content-page .module-item {
  border: 1px solid #000;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 10px;
}
app-aboutus ion-content.about-page .content-page .module-item h3 {
  font-size: 13px;
  text-align: center;
  margin: 0;
}
app-aboutus ion-content.about-page .btn-final {
  margin-top: 30px;
}